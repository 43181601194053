@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.bg-image {
    background-image: url("./assets/pum-bg.png");
    background-size: cover;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.ant-select-selector {
    height: 40px !important;
    margin: auto 0;
    display: flex;
    align-items: center;
}

/* Firefox and other Mozilla-based browsers */
.scrollbar-width {
    scrollbar-width: thin;
}

/* Chrome, Edge, Safari, and other WebKit-based browsers */
.scrollbar-width::-webkit-scrollbar {
    width: 10px;
}


